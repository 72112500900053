import { buildOptions, checkResponse } from './serviceHelpers';
import buildUrl from 'build-url';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { HttpError } from '../types';

type HttpMethod = 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH';

type FetchWithAuth = {
  endpointUrl: string;
  method?: HttpMethod;
  route?: string;
  body?: Record<string, unknown>;
  params?: Record<string, string | string[] | undefined>;
  accessToken: string;
  additionalHeaders?: Record<string, string>;
  giveSimpleResponse?: boolean;
};

export const fetchWithAuth = async <T>({
  endpointUrl,
  method = 'GET',
  route,
  body,
  params,
  accessToken,
  additionalHeaders = {},
  giveSimpleResponse = true,
}: FetchWithAuth): Promise<T> => {
  const queryParams = params && (omitBy(params, isNil) as Record<string, string | string[]> | undefined);
  const options = buildOptions(method, body, additionalHeaders, accessToken);
  const fullUrl = buildUrl(endpointUrl, {
    path: route,
    queryParams,
    disableCSV: true,
  });

  try {
    const rawResponse = await fetch(fullUrl, options);

    // Handle errors in the API response in a way that allows us to access the status code
    if (!rawResponse.ok) {
      throw new HttpError(rawResponse.status, ((await rawResponse.json()) as Error).message);
    }

    const parsedResponse = await checkResponse(rawResponse, `${method} ${fullUrl}`, giveSimpleResponse);
    return parsedResponse;
  } catch (error) {
    if (process.env.NODE_ENV !== 'test') {
      console.error(error);
    }
    throw error;
  }
};

export default fetchWithAuth;
