import React from 'react';
import { css } from '@emotion/css';
import { FulfillmentConfiguration } from '../services/fulfillmentConfiguration';

type FulfillmentConfigurationTextProps = {
  fulfillmentConfiguration: FulfillmentConfiguration | undefined;
};

const fulfillmentConfigurationCss = css`
  display: flex;
  font-size: 0.938rem;
  margin-bottom: 10px;
`;

const fulfilledViaCss = css`
  margin-right: 5px;
`;

const fulfillmentConfigurationLinkCss = css`
  font-weight: bold;
`;

export const FulfillmentConfigurationText = ({ fulfillmentConfiguration }: FulfillmentConfigurationTextProps) => {
  if (!fulfillmentConfiguration) {
    return <div></div>;
  }

  return (
    <div className={fulfillmentConfigurationCss}>
      <div className={fulfilledViaCss}>Fulfilled via:</div>
      <a
        className={fulfillmentConfigurationLinkCss}
        target="_blank"
        rel="noreferrer"
        href={fulfillmentConfiguration._links.ui.href}
      >
        {fulfillmentConfiguration.name}
      </a>
    </div>
  );
};
