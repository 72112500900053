type ErrorCodes = {
  errorCodes: number[];
};

/**
 * Sometimes, we get an error from a service that's expected,
 * such as inventory not being configured for a fulfiller,
 * and we don't want that to trigger re-fetching behavior by Tanstack
 * Query. These errors are represented here.
 */
const ERRORS_UNLIKELY_TO_CHANGE: { [key: string]: ErrorCodes } = {
  'inventory.commerce.cimpress.io': {
    errorCodes: [404],
  },
};

export const isRequestResponseLikelyToChange = (url: string | undefined, errorCode: number | undefined) => {
  // Don't retry if we don't have a URL because the request will definitely fail
  if (!url) {
    return false;
  }

  // If there wasn't an error, retry the request on mount when it becomes stale
  if (!errorCode) {
    return true;
  }

  const hostname = new URL(url).hostname;

  // Need to also handle int URLs, so this allows us to check for both (assumes int URLs are `int-[prd URL]`)
  const errorCodesKey = Object.keys(ERRORS_UNLIKELY_TO_CHANGE).find(key => hostname.includes(key));
  const applicableErrorsUnlikelyToChange: ErrorCodes | undefined = errorCodesKey
    ? ERRORS_UNLIKELY_TO_CHANGE[errorCodesKey]
    : undefined;

  // If there aren't any errors that we expect to remain the same for this hostname,
  // we should retry the request on mount when it becomes stale (may have gotten a transient error)
  if (!applicableErrorsUnlikelyToChange) {
    return true;
  }

  const { errorCodes } = applicableErrorsUnlikelyToChange;

  const foundErrorCode = errorCodes.find(code => code === errorCode);

  // If we didn't find a matching error code for this hostname in the list of error codes we expect to remain the same,
  // we should retry the request on mount when it becomes stale (may have gotten a transient error)
  if (!foundErrorCode) {
    return true;
  }

  // Don't retry requests with errors we expect to stay the same for this hostname
  return false;
};
