export type ShipDateResponse = {
  earliestShipDateTime: string;
  expectedProductionStartDateTime: string;
  nextCutoffTime: string;
};

export type PackEvaluation = {
  _embedded: {
    selectedPacks: {
      quantity: number;
      _links: {
        pack: {
          href: string;
        };
      };
    }[];
  };
};

export class HttpError extends Error {
  constructor(public status: number, message: string) {
    super(message);
    this.name = 'HttpError';
  }
}
