import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@cimpress/react-components';
import { languageCodes, getLanguageOptions } from '../constants/languages';
import { getFirstSupportedLanguage } from '../utils/languages';
import locales from '../constants/locales';
import timeZones from '../constants/timeZones';
import { withTranslation } from 'react-i18next';
import { getI18nInstance } from '../i18n';

class GlobalSettings extends Component {
  static propTypes = {
    lang: PropTypes.string,
    language: PropTypes.string,
    locale: PropTypes.string,
    timeZone: PropTypes.string,
    onLanguageUpdated: PropTypes.func.isRequired,
    onLocaleUpdated: PropTypes.func.isRequired,
    onTimeZoneUpdated: PropTypes.func.isRequired,
    supportedLanguages: PropTypes.arrayOf(PropTypes.oneOf(languageCodes)).isRequired,
  };

  state = {
    languageOptions: getLanguageOptions(this.props.supportedLanguages),
  };

  onLanguageSelected = language => this.props.onLanguageUpdated(language.value);

  onLocaleSelected = locale => this.props.onLocaleUpdated(locale.value);

  onTimeZoneSelected = timeZone => this.props.onTimeZoneUpdated(timeZone.value);

  tt = key => {
    const { t, lang } = this.props;
    return t(key, { lng: lang });
  };

  render() {
    const { language, locale, timeZone } = this.props;

    const menuPortalTarget = document.getElementById('settings-modal');

    return (
      <Fragment>
        <Select
          name="language"
          placeholder={this.tt('global_settings_language_placeholder')}
          options={this.state.languageOptions}
          simpleValue
          value={this.state.languageOptions.filter(
            o => o.value === getFirstSupportedLanguage(language, this.props.supportedLanguages)
          )}
          onChange={this.onLanguageSelected}
          menuPortalTarget={menuPortalTarget}
          helpText={this.tt('global_settings_language_help_text')}
          isClearable={false}
        />
        <Select
          style={{ marginTop: '15px' }}
          name="locale"
          placeholder={this.tt('global_settings_locale_placeholder')}
          options={locales}
          simpleValue
          value={locales.filter(o => o.value === locale)}
          onChange={this.onLocaleSelected}
          menuPortalTarget={menuPortalTarget}
          helpText={this.tt('global_settings_locale_help_text')}
          isClearable={false}
        />
        <Select
          style={{ marginTop: '15px' }}
          name="timeZone"
          placeholder={this.tt('global_settings_timezone_placeholder')}
          options={timeZones}
          simpleValue
          value={timeZones.filter(o => o.value === timeZone)}
          onChange={this.onTimeZoneSelected}
          menuPortalTarget={menuPortalTarget}
          helpText={this.tt('global_settings_timezone_help_text')}
          isClearable={false}
        />
      </Fragment>
    );
  }
}

const GlobalSettingsWithTranslation = withTranslation()(GlobalSettings);
export default props => <GlobalSettingsWithTranslation i18n={getI18nInstance()} {...props} />;
