import { Moment } from 'moment';
import { useQuery } from 'react-query';
import { AddressType, DebugBranch, getDebugBranches } from '../services/itemDeliveryPossibilities';

export type UseDebugBranchesInput = {
  accessToken: string;
  productConfigurationUrl: string;
  merchantId: string;
  quantity: number;
  country?: string | undefined;
  postalCode?: string | undefined;
  pickupPointUrl?: string | undefined;
  isPOBox?: boolean | undefined;
  addressType?: AddressType | undefined;
  minutesToOrderSubmittal?: number | undefined;
  requestDateTime?: Moment | undefined;
  fulfillmentCapabilities?: string[] | undefined;
  carrierServices?: string[] | undefined;
  carrierServiceCapabilities?: string[] | undefined;
  currencyCode?: string | undefined;
  ignoreInTransitInventory?: boolean;
  edoUrl?: string | undefined;
  onSuccess?: (data: DebugBranch[]) => void;
};

export const useDebugBranches = ({
  accessToken,
  productConfigurationUrl,
  merchantId,
  quantity,
  country,
  postalCode,
  pickupPointUrl,
  isPOBox,
  addressType,
  minutesToOrderSubmittal,
  requestDateTime,
  fulfillmentCapabilities,
  carrierServices,
  carrierServiceCapabilities,
  currencyCode,
  ignoreInTransitInventory,
  edoUrl,
  onSuccess,
}: UseDebugBranchesInput) => {
  return useQuery(
    [
      'debugBranches',
      productConfigurationUrl,
      merchantId,
      quantity,
      country,
      postalCode,
      pickupPointUrl,
      isPOBox,
      addressType,
      minutesToOrderSubmittal,
      requestDateTime,
      fulfillmentCapabilities,
      carrierServices,
      carrierServiceCapabilities,
      currencyCode,
      ignoreInTransitInventory,
      edoUrl,
    ],
    () =>
      getDebugBranches(accessToken, {
        productConfigurationUrl,
        merchantId,
        quantity,
        country,
        postalCode,
        pickupPointUrl,
        isPOBox,
        addressType,
        minutesToOrderSubmittal,
        requestDateTime,
        fulfillmentCapabilities,
        carrierServices,
        carrierServiceCapabilities,
        currencyCode,
        ignoreInTransitInventory,
        edoUrl,
      }),
    {
      onSuccess,
    },
  );
};
